import React, { useContext } from 'react';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { LayoutContext } from '../../CommonLayout';

import styles from './index.module.scss';

const antIcon = (
  <LoadingOutlined style={{ fontSize: 56, top: 'calc(50% - 50px)' }} spin />
);

const Loading: React.FC = () => {
  const layout = useContext(LayoutContext);
  let wrapperClassName = styles.loadingWrapper;

  if (layout === 'collapsedSideMenu') {
    wrapperClassName = `${wrapperClassName} ${styles.positionCenter}`;
  } else {
    wrapperClassName = `${wrapperClassName} ${styles.positionInRightPart}`;
  }

  return (
    <div className={wrapperClassName}>
      <Spin indicator={antIcon} />
    </div>
  );
};
export default Loading;
