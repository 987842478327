import React, { useState, useEffect } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { useMount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';

import MessageModal from '../../../system/components/MessageModal';
import * as updateAction from '../../../../utils/actions/UpdateStatus';
import * as userInfoActions from '../../../../utils/actions/UserInfo';
import { RootState } from '../../../../rootReducer';
import CommonPageContentContainer, {
  OperationBtnProps,
} from '../../components/CommonPageContentContainer';
import Loading from '../../components/Loading';
import UserEditForm, {
  UserEditFormEditableFields,
  UserFormErrorMsg,
  UserFormIdMap,
} from '../../components/UserEditForm';

import {
  API_GET_MY_INFO,
  API_GET_USER_UPDATE,
  ROUTE_TOP,
  ROUTE_USER_INFO_REGISTER,
  UPDATE_ACTION_UPDATE,
} from '../../../../utils/constants';
import {
  scrollToTargetElement,
  validateTel,
  isNotEmptyString,
} from '../../../../utils/helper';
import useFetchData, {
  UPDATE_ACTION_SUCCESS,
} from '../../../../utils/hooks/useFetch';
import Messages from '../../../../utils/messages';
import {
  UserInfoWithOperationAuth,
  generateDefaultUserInfo,
  Organization,
  Position,
} from '../../../../api/user';
import { get, put } from '../../../../utils/fetch';

import styles from './index.module.scss';

const UserEdit: React.FC<RouteComponentProps<{}>> = (
  props: RouteComponentProps<{}>
) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isRegister = location.pathname === ROUTE_USER_INFO_REGISTER;
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const defaultUser = generateDefaultUserInfo();
  const [userState, fetchUser] = useFetchData<UserInfoWithOperationAuth>(
    get,
    defaultUser
  );
  const [inputCheckResult, setInputCheckResult] = useState<UserFormErrorMsg>();
  const [upsertUserResult, upsertUser] = useFetchData<string>(put, '');
  const [user, setUser] = useState(defaultUser);
  const updateStatusRedux = useSelector<RootState, number>(
    (state) => state.updateStatus
  );

  const hasStaffCode = () => {
    if (userState.data) {
      return isNotEmptyString(userState.data.staffCode);
    }
    return false;
  };

  const handleMailChange = (mail: string): void => {
    setUser((prevState) => {
      return { ...prevState, ...{ emailAddress: mail } };
    });
  };

  const handleFamilyNameChange = (familyName: string): void => {
    setUser((prevState) => {
      return { ...prevState, ...{ familyName: familyName } };
    });
  };

  const handleGivenNameChange = (givenName: string): void => {
    setUser((prevState) => {
      return { ...prevState, ...{ givenName: givenName } };
    });
  };

  const handleExecutiveCodeChange = (executiveCode: string): void => {
    setUser((prevState) => {
      return { ...prevState, ...{ executiveCode: executiveCode } };
    });
  };

  const handlePhoneChange = (tel: string): void => {
    setUser((prevState) => {
      return { ...prevState, ...{ tel: tel } };
    });
  };

  const handleOrgInChargeCodeChange = (id: string): void => {
    if (user.orgsBelongTo) {
      const target = user.orgsBelongTo.find((item: Organization) => {
        return item.orgId === id;
      });
      setUser((prevState) => {
        return {
          ...prevState,
          ...{
            orgInChargeCode: id,
            orgInChargeName: target ? target.orgName : '',
            mainPositionCode: '',
            mainPositionName: '',
          },
        };
      });
    }
  };

  const handleMainPositionCodeChange = (id: string): void => {
    if (user.orgsBelongTo) {
      const org = user.orgsBelongTo.find((item: Organization) => {
        return item.orgId === user.orgInChargeCode;
      });
      if (org && org.positions) {
        const target = org.positions.find((item: Position) => {
          return item.positionId === id;
        });
        setUser((prevState) => {
          return {
            ...prevState,
            ...{
              mainPositionCode: id,
              mainPositionName: target ? target.positionName : '',
            },
          };
        });
      }
    }
  };

  /**
   * 画面位置一番上部の問題ある部品に遷移する
   * @param error
   * @returns
   */
  const scrollToTopErrorItemId = (
    error: UserFormErrorMsg | undefined
  ): void => {
    if (!error) {
      return;
    }
    if (error.phone) {
      scrollToTargetElement(UserFormIdMap.phone);
    }
  };

  const handleCancelClick = () => {
    props.history.push(ROUTE_TOP);
  };

  const handleSaveClick = () => {
    const errorMsg: UserFormErrorMsg = {};
    if (!isNotEmptyString(user.familyName)) {
      errorMsg.familyName = Messages['input.check.must.input'];
    }
    if (!isNotEmptyString(user.givenName)) {
      errorMsg.givenName = Messages['input.check.must.input'];
    }
    if (!isNotEmptyString(user.tel)) {
      errorMsg.phone = `${Messages['input.check.must.input']}(${Messages['user.phone.number']})`;
    } else if (!validateTel(user.tel)) {
      errorMsg.phone = `${Messages['input.check.error.requirnumber']}(${Messages['user.phone.number']})`;
    }

    if (!hasStaffCode() && !isNotEmptyString(user.mainPositionCode)) {
      errorMsg.mainPosition = `${Messages['input.check.must.input']}(${Messages['user.mainPosition']})`;
    }

    if (!hasStaffCode() && !isNotEmptyString(user.orgInChargeCode)) {
      errorMsg.orgInCharge = `${Messages['input.check.must.input']}(${Messages['user.orgInCharge']})`;
    }

    const errorMsgKeys = Object.keys(errorMsg);
    if (!errorMsgKeys || errorMsgKeys.length === 0) {
      setInputCheckResult(undefined);
      upsertUser({
        url: API_GET_USER_UPDATE,
        data: user,
        method: UPDATE_ACTION_UPDATE,
      });
      return;
    }
    setInputCheckResult(errorMsg);
    scrollToTopErrorItemId(errorMsg);
  };

  const generateOperatorBtns = (): OperationBtnProps[] => {
    const btns: OperationBtnProps[] = [];
    if (isRegister) {
      btns.push({
        type: 'primary',
        title: Messages['management.commom.operator.register'],
        onClick: handleSaveClick,
      });
    } else {
      btns.push({
        type: 'secondary',
        title: Messages['management.commom.operator.return.top'],
        onClick: handleCancelClick,
      });

      btns.push({
        type: 'primary',
        title: Messages['management.commom.operator.save'],
        onClick: handleSaveClick,
      });
    }

    return btns;
  };

  useMount(() => {
    fetchUser({ url: API_GET_MY_INFO });
  });

  useEffect(() => {
    if (
      upsertUserResult &&
      upsertUserResult.data === UPDATE_ACTION_SUCCESS &&
      !upsertUserResult.isFetching
    ) {
      if (isRegister) {
        props.history.push(ROUTE_TOP);
      }
      // SG_VENUS_DEV-1065 【仕様変更】個人設定編集画面で保存ボタン押下後にメニューを取得しなおす
      dispatch(userInfoActions.refreshUserInfoFlag());
      dispatch(updateAction.setUpdateSuccess());
    }
  }, [upsertUserResult]);

  useEffect(() => {
    if (userState && userState.data && userState.data.commonId !== '') {
      setUser(userState.data);
    }
    if (
      updateStatusRedux === updateAction.UPDATE_STATUS_SUCCESS ||
      updateStatusRedux === updateAction.DEL_STATUS_SUCCESS
    ) {
      setShowAlert(true);
      dispatch(updateAction.removeUpdateStatus());
    }
  }, [userState]);

  if (userState.isFetching || upsertUserResult.isFetching) {
    return <Loading />;
  }

  const generateEditableFields = (): UserEditFormEditableFields => {
    return {
      emailAddress: false,
      familyName: true,
      givenName: true,
      executiveCode: false,
      orgInCharge: true,
      mainPosition: true,
      phone: true,
    };
  };

  const pageName = () => {
    if (isRegister) {
      return Messages['user.register.page.name'];
    }
    return Messages['user.edit.page.name'];
  };

  return (
    <div className={styles.registerWrapper}>
      <CommonPageContentContainer
        pageTitle={pageName()}
        btns={generateOperatorBtns()}
        noFix
      >
        <UserEditForm
          user={user}
          editableFields={generateEditableFields()}
          errorMsg={inputCheckResult}
          onMailChange={handleMailChange}
          onFamilyNameChange={handleFamilyNameChange}
          onGivenNameChange={handleGivenNameChange}
          onExecutiveCodeChange={handleExecutiveCodeChange}
          onPhoneChange={handlePhoneChange}
          onOrgInChargeCodeChange={handleOrgInChargeCodeChange}
          onMainPositionCodeChange={handleMainPositionCodeChange}
        />
        <MessageModal
          message={Messages['alert.update.success']}
          show={showAlert}
          closeCallBack={() => {
            setShowAlert(false);
          }}
        />
      </CommonPageContentContainer>
    </div>
  );
};

export default UserEdit;
